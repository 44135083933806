$(function() {

    console.log('blacklist ready');
    $('#get-blacklist').on('click', function () {
        $.get('/admin/blacklist', function(response) {
            var $modal = $('#blacklistModal');
            var text = response.join(",\n") + ',';
            console.log('manually');
            var $temp = $('<textarea class="form-control" rows="8"></textarea>');
            $temp.val(text).select();
            $modal.find('textarea').remove();
            $modal.find('.modal-body').append($temp);
            $modal.modal('show');
        })
        .fail(function () {
            alert('Error getting blacklist');
        });
    });

    $('#blacklistCopyButton').on('click', function () {
        $('#blacklistModal').find('textarea').first().select();
        var result = document.execCommand("copy");
    });
});

function copyToClipboard(text) {
    console.log('copying');
    var $temp = $("<textarea></textarea>");
    $('body').append($temp);
    $temp.val(text).select();
    var result = document.execCommand("copy");
    console.log(result);
    $temp.remove();
}
